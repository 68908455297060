import React from 'react';
import ReactEcharts from 'echarts-for-react';

const Gauge = ({ aqi }) => {
  const colors = [
    '#00E400',
    '#FFFF00',
    '#FF7E00',
    '#FF0000',
    '#8F3F97',
    '#7E0023',
  ];
  const levelsOfConcern = [
    'Good',
    'Moderate',
    'Unhealthy for Sensitive Groups',
    'Unhealthy',
    'Very Unhealthy',
    'Hazardous',
  ];

  const getIndex = (value) => {
    let index = 0;
    if (value > 50 && value < 101) {
      index = 1;
    } else if (value > 100 && value < 151) {
      index = 2;
    } else if (value > 150 && value < 201) {
      index = 3;
    } else if (value > 200 && value < 301) {
      index = 4;
    } else if (value > 300) {
      index = 5;
    }
    return index;
  };

  const getColor = (value) => {
    return colors[getIndex(value)];
  };

  const getText = (value) => {
    return levelsOfConcern[getIndex(value)];
  };

  const option = {
    tooltip: {
      formatter: '{a} : {c}',
    },
    grid: {
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
    },
    series: [
      {
        name: 'Air Quality Index',
        type: 'gauge',
        detail: {
          formatter: '{value}',
          fontSize: 32,
          fontWeight: 'bold',
        },
        data: [{ value: aqi, name: getText(aqi) }],
        min: 0,
        max: 500,
        title: {
          offsetCenter: [0, '120%'],
          fontSize: 18,
          backgroundColor: getColor(aqi),
          color: '#fff',
          padding: 10,
          borderRadius: 5,
        },
        pointer: {
          width: 2,
          length: '100%',
        },
        axisLine: {
          lineStyle: {
            width: 12,
            color: [
              [0.1, colors[0]],
              [0.2, colors[1]],
              [0.3, colors[2]],
              [0.4, colors[3]],
              [0.6, colors[4]],
              [1, colors[5]],
            ],
          },
        },
        axisLabel: {
          fontSize: 12,
          fontWeight: 'bold',
        },
        splitLine: {
          length: 15,
        },
      },
    ],
  };
  return <ReactEcharts option={option} />;
};

export default Gauge;
