import { Link } from 'react-router-dom';
import * as dayjs from 'dayjs';
import React from 'react';

import './Header.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const Header = ({ city, country, zipCode, place, state, lastUpdated }) => {
  const getLastUpdatedSegment = (timestamp, format) =>
    dayjs(timestamp).format(format);
  const lastUpdatedString = `${getLastUpdatedSegment(
    lastUpdated,
    'MMMM DD, YYYY'
  )}. Last updated: ${getLastUpdatedSegment(lastUpdated, 'h:mma')}`;

  return (
    <Navbar bg="light" expand="lg">
      <Link to="/">
        <Navbar.Brand href="#home">
          <strong>Atmosome</strong>
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Navbar.Text>
          <span>
            <span role="img" aria-label="Location">
              📍
            </span>{' '}
            <span className="mr-5">
              {place}. Address: {city}, {state}, {country}. {zipCode}
            </span>
          </span>
          <span>
            <span role="img" aria-label="Time">
              ⌚
            </span>{' '}
            <span> {lastUpdatedString}</span>
          </span>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
