import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import React from 'react';

import './App.css';
import Dashboard from './pages/Dashboard';
import Details from './pages/Details';
import Landing from './pages/Landing';

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route path="/dashboard">
            <Dashboard />
          </Route>
          <Route path="/details">
            <Details />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
