import sanitizeHtml from 'sanitize-html';

const sanitizeContent = (content) =>
  sanitizeHtml(content, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['h1', 'h2', 'img']),
    allowedAttributes: {
      ...sanitizeHtml.defaults.allowedAttributes,
      img: ['src', 'style', 'alt', 'class'],
    },
  });

export default sanitizeContent;
