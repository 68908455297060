import { Link } from 'react-router-dom';
import React from 'react';

import './WeatherUnit.css';
import altitude from '../assets/altitude.svg';
import humidity from '../assets/humidity.svg';
import pressure from '../assets/pressure.svg';
import temperature from '../assets/temperature.svg';

const unitIconMap = {
  ALTITUDE: altitude,
  HUMIDITY: humidity,
  PRESSURE: pressure,
  TEMPERATURE: temperature,
};

const WeatherUnit = ({ abbr, name, value, state, unit }) => {
  return (
    <Link
      target="_blank"
      className="weather-detail-link"
      to={`/details?name=${abbr}`}
    >
      <div className="media weather-media">
        <img
          src={unitIconMap[name.toUpperCase()]}
          className="align-self-center mr-3"
          width={50}
          alt={name}
        />
        <div className="media-body weather-media-body">
          <p className="weather-unit-name">{name}</p>
          <h5 className="mt-0">
            <span className="weather-unit-value">{+value ? value.toFixed(2) : value}</span>
            <span className="weather-unit-unit">{unit}</span>
          </h5>
        </div>
      </div>
    </Link>
  );
};

export default WeatherUnit;
