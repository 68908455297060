import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';
import bg from '../assets/bg.jpg';

import './Landing.css';

export default function Landing() {
  const [zipCodes, setZipCodes] = useState([]);
  const history = useHistory();

  const handleChange = ({ target: { value } }) =>
    history.push('/dashboard', { selectedZipCode: value });

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_ROOT}zipcodes`;
    fetch(apiUrl)
      .then((res) => res.json())
      .then((supportedZipCodes) => setZipCodes(supportedZipCodes));
  }, []);

  return (
    <main>
      <section>
        <img src={bg} alt="Welcome to Atmosome" />
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
          <h1>Welcome to Atmosome</h1>
          <Form.Group>
            <Form.Control as="select" size="lg" onChange={handleChange}>
              <option selected disabled>
                Please select your Location
              </option>
              {zipCodes.map((zip) => (
                <option value={zip} key={zip}>
                  {zip}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </div>
      </section>
      <section>
        <p>
          Modern health research is increasingly separated into various
          â€omicsâ€ fields. Omics provides a quantified approach to the total
          collective information about the various realms of biology. One aspect
          of omics called the exposome relates to the collective exposures an
          individual is placed in throughout their life. A subset of this
          exposome, is the atmospheric exposome, which we name as the
          â€atmosomeâ€. Air surrounds individuals from the moment they are born
          till they take their last breath. The atmosphere is constantly
          changing, given our location and surroundings are always dynamic
          throughout life. The atmosphere inevitably plays a massive role in our
          health such as impact on DNA damage, metabolism, skin integrity, and
          lung health. The atmosome sensing system is an IoT,
          microcontroller-based system which collects real-time individual air
          quality data and posts it to a cloud server for immediate access.
          Quantifying the individual atmosome is a next step in advancing
          personalized health and medical research. Our experimental results
          demonstrate the accuracy of the data we collected and the avenues our
          system creates for direct lifestyle to environment correlations. View
          your data now.
        </p>
      </section>
    </main>
  );
}
