import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import sanitizeContent from '../utils/sanitization';

const Details = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const name = queryParams.get('name');
  const [content, setContent] = useState('');
  const apiUrl = `${process.env.REACT_APP_API_ROOT}atmosome/html/${name}`;

  useEffect(() => {
    fetch(apiUrl)
      .then((res) => res.json())
      .then(({ content }) => setContent(content));
  }, [apiUrl, name]);

  return (
    <div className="container-fluid">
      <h1>{name}</h1>
      <section
        dangerouslySetInnerHTML={{
          __html: sanitizeContent(content),
        }}
      ></section>
    </div>
  );
};

export default Details;
