import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import './Dashboard.css';
import Gauge from '../components/Gauge';
import Header from '../components/Header';
import Pollutant from '../components/Pollutant';
import WeatherUnit from '../components/WeatherUnit';

const Dashboard = (props) => {
  const [AQI, setAQI] = useState(0);
  const [locationDetails, setLocationDetails] = useState({});
  const [pollutants, setPollutants] = useState([]);
  const [weatherDetails, setWeatherDetails] = useState([]);
  const [cumulativeExposure, setCumulativeExposure] = useState([]);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const { state: locationState } = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (locationState && locationState.selectedZipCode) {
      const apiUrl = `${process.env.REACT_APP_API_ROOT}zipcode/${locationState.selectedZipCode}/atmosome/`;

      fetch(`${apiUrl}summary`)
        .then((res) => res.json())
        .then(({ aqi, pollutantDetails, locationDetails, weatherDetails }) => {
          setAQI(aqi);
          setPollutants(pollutantDetails);
          setLocationDetails(locationDetails);
          setWeatherDetails(weatherDetails);
        });

      fetch(`${apiUrl}cumulative`)
        .then((res) => res.json())
        .then(({ averageExposure }) => setCumulativeExposure(averageExposure));

      fetch(`${apiUrl}timeseries`)
        .then((res) => res.json())
        .then(({ timeSeriesData }) => setTimeSeriesData(timeSeriesData));
    } else {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { city, state, zipCode, place, country, lastUpdated } = locationDetails;

  return (
    <div>
      <Header
        city={city}
        country={country}
        zipCode={zipCode}
        place={place}
        state={state}
        lastUpdated={lastUpdated}
      />
      <main className="container-fluid">
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12">
                <h2>AQI(US)</h2>
                <Gauge aqi={AQI} />
              </div>

              <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12">
                <h2>Weather</h2>
                <div className="row">
                  {weatherDetails
                    .sort((a, b) => a.order - b.order)
                    .map(({ abbr, name, value, state, unit }) => (
                      <div
                        key={name}
                        className="col-xl-6 col-lg-6 col-md-12 col-sm-6 col-xs-12"
                      >
                        <WeatherUnit
                          abbr={abbr}
                          name={name}
                          key={name}
                          value={value}
                          state={state}
                          unit={unit}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <h2>Pollutants</h2>
            <div className="row">
              {pollutants
                .sort((a, b) => a.order - b.order)
                .map(
                  ({
                    abbr,
                    barColor,
                    barProgress,
                    name,
                    state,
                    tipsContent,
                    tipsTitle,
                    units,
                    value,
                  }) => (
                    <div
                      key={name}
                      className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12"
                    >
                      <Pollutant
                        abbr={abbr}
                        barColor={barColor}
                        barProgress={barProgress}
                        name={name}
                        state={state}
                        tipsContent={tipsContent}
                        tipsTitle={tipsTitle}
                        units={units}
                        value={value}
                      />
                    </div>
                  )
                )}
            </div>
          </div>
        </div>

        <div>
          <h2>Average Exposure</h2>
          <div className="row">
            {cumulativeExposure
              .sort((a, b) => a.order - b.order)
              .map(({ name, unit, stats }) => (
                <div
                  key={name}
                  className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-xs-12"
                >
                  <h3>{name}</h3>
                  <table className="table table-bordered table-hover">
                    <tbody>
                      {stats
                        .sort((a, b) => a.order - b.order)
                        .map((stat) => (
                          <tr>
                            <th scope="row">{stat.title}</th>
                            <td>
                              {stat.stats} {unit}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ))}
          </div>
        </div>
        <div>
          <h2>Time Series Data</h2>
          <div className="row">
            {timeSeriesData
              .sort((a, b) => a.order - b.order)
              .map(({ name, graphImageUrl }) => (
                <div
                  key={name}
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                >
                  <div className="dashboard-graph-img-container">
                    <h3>{name}</h3>
                    <img
                      className="img-responsive graph"
                      alt={`${name} Time Series Data`}
                      src={graphImageUrl}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;
