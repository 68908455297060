import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ProgressBar from 'react-bootstrap/ProgressBar';
import React from 'react';
import { Link } from 'react-router-dom';

import './Pollutant.css';
import sanitizeContent from '../utils/sanitization';

const stateColorMap = {
  green: 'success',
  blue: 'info',
  orange: 'warning',
  red: 'danger',
};

const Pollutant = ({
  abbr,
  barColor,
  barProgress,
  name,
  state,
  tipsContent,
  tipsTitle,
  units,
  value,
}) => {
  return (
    <div className="card pollutant-card">
      <div className="pollutant-card-body">
        <span>
          <Link
            target="_blank"
            className="pollutant-detail-link"
            to={`/details?name=${abbr}`}
          >
            <strong>
              {name} - {abbr}
            </strong>
          </Link>
        </span>
        <span className="pollutant-flex">
          <span className="float-right">
            <strong>{+value ? value.toFixed(2) : value}</strong>
          </span>
          <span className="pollutant-unit float-right">{units}</span>
        </span>
      </div>
      <ProgressBar
        className="pollutant-progress-bar"
        variant={stateColorMap[barColor]}
        now={barProgress}
      />
      <div className="pollutant-card-footer">
        <span>{state}</span>
        {tipsTitle ? (
          <OverlayTrigger
            trigger="click"
            placement="left"
            overlay={
              <Popover id={`popover-positioned-bottom`}>
                <Popover.Title as="h3">{tipsTitle}</Popover.Title>
                <Popover.Content
                  dangerouslySetInnerHTML={{
                    __html: sanitizeContent(tipsContent),
                  }}
                ></Popover.Content>
              </Popover>
            }
          >
            <Button className="pollutant-link-btn" variant="link">
              Tips
            </Button>
          </OverlayTrigger>
        ) : null}
      </div>
    </div>
  );
};

export default Pollutant;
